import { Box, Paper, Typography } from "@mui/material";
import { useAdminParams, useStats } from "../../utils/hooks";

export const Stats = (props: { filter: useAdminParams }) => {
  const stats = useStats(props.filter);

  const statItems = [
    {
      title: "Total Organizations",
      value: stats.total,
    },
    {
      title: "Successful Organizations",
      value: stats.successful,
      percentage: `${((stats.successful / (stats.successful + stats.failed)) * 100).toFixed(2) || 0}%`,
    },
    {
      title: "Failed Organizations",
      value: stats.failed,
      percentage: `${((stats.failed / (stats.successful + stats.failed)) * 100).toFixed(2) || 0}%`,
    },
  ];

  return (
    <Box sx={{ display: "flex", gap: 3, mb: 4 }}>
      {statItems.map((item, index) => (
        <Paper
          key={index}
          sx={{
            flex: 1,
            p: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            component="div"
            fontSize={36}
            sx={{ fontWeight: "bold", color: "text.primary" }}
          >
            {item.value.toLocaleString()}
          </Typography>
          <Typography sx={{ color: "text.secondary", mt: 1, fontSize: 14 }}>
            {item.title}
          </Typography>
          {item.percentage && (
            <Typography sx={{ color: "text.secondary", mt: 1, fontSize: 14 }}>
              {item.percentage}
            </Typography>
          )}
        </Paper>
      ))}
    </Box>
  );
};
