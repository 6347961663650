import { Box, Button, TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useState, memo } from "react";

interface SearchBarProps {
  onSearch: (query: string) => void;
}

export const SearchBar = memo(({ onSearch }: SearchBarProps) => {
  const [value, setValue] = useState("");

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSearch(value);
  };

  return (
    <form onSubmit={handleSubmit} style={{ flex: 1 }}>
      <Box sx={{ display: "flex", gap: 1, width: "100%" }}>
        <TextField
          fullWidth
          size="small"
          placeholder="Search organizations..."
          value={value}
          onChange={(e) => setValue(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Button type="submit" variant="contained">
          Search
        </Button>
      </Box>
    </form>
  );
});
