import useSWR from "swr";
import { AdminResponse } from "./types";
import { fetcher } from "./fetchData";
import { SortOptions } from "../components/admin/SortControls";
import { Filters } from "../components/admin/FilterBar";

interface SWRParams {
  type: string;
  page: number;
  pageSize: number;
  search: string;
  state: string | null;
  failedScrape: boolean;
}

export interface useAdminParams {
  type: "orgs" | "locations";
  pagination: {
    page: number;
    pageSize: number;
  };
  searchQuery?: string;
  filters: Filters;
  sort?: SortOptions;
}

export const useAdmin = (params: useAdminParams) => {
  const queryParams = new URLSearchParams({
    type: params.type,
    page: params.pagination.page.toString(),
    pageSize: params.pagination.pageSize.toString(),
  });

  if (params.searchQuery) {
    queryParams.set("search", params.searchQuery);
  }

  if (params.filters) {
    if (params.filters.state) {
      queryParams.set("state", params.filters.state);
    }
    if (params.filters.failedScrape) {
      queryParams.set("failedScrape", "true");
    }
    if (params.filters.startDate) {
      queryParams.set("startDate", params.filters.startDate);
    }
    if (params.filters.endDate) {
      queryParams.set("endDate", params.filters.endDate);
    }
  }

  if (params.sort) {
    queryParams.set("sortBy", params.sort.sortBy);
    queryParams.set("sortOrder", params.sort.sortOrder);
  }

  return useSWR(
    ["/admin", params],
    async () => {
      const response = await fetcher<never, any>(
        "GET",
        `/admin?${queryParams.toString()}`
      );
      return response;
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval: 15 * 60 * 1000,
    }
  );
};

export const useFailed = (filter: useAdminParams) => {
  const data = useAdmin({
    ...filter,
    pagination: { ...filter.pagination, page: 1 },
    filters: { ...filter.filters, failedScrape: true },
  });

  return { ...data, data: data.data?.data.total };
};

export const useStats = (filter: useAdminParams) => {
  const { data: failed } = useFailed(filter);
  const { data: filteredTotal } = useAdmin(filter);
  const { data: total } = useAdmin({
    type: "orgs",
    pagination: { ...filter.pagination, page: 1 },
    filters: {
      state: null,
      failedScrape: false,
      startDate: null,
      endDate: null,
    },
  });

  return {
    successful: filteredTotal?.data.total - failed || 0,
    failed: failed || 0,
    total: total?.data.total || 0,
  };
};
