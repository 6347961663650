import { Box, Paper, Skeleton, Typography } from "@mui/material";
import useSWR from "swr";
import { fetcher } from "../../utils/fetchData";

interface CountsResponse {
  success: boolean;
  data: {
    voCount: number;
    orgCount: number;
  };
}

export const StatisticsSidebar = () => {
  const { data: countsData, isLoading } =
    useSWR<CountsResponse>("/admin?type=count");

  if (isLoading) {
    return <Skeleton variant="rectangular" height={160} />;
  }

  console.log(countsData);

  return (
    <Box
      sx={{
        width: 400,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        pl: 2,
      }}
    >
      <Paper elevation={0} sx={{ p: 2, textAlign: "center" }}>
        <Typography variant="h4" color="text.secondary">
          Organizations
        </Typography>
        <Typography variant="h1" color="primary">
          {countsData?.data.orgCount ?? 0}
        </Typography>
      </Paper>

      <Paper elevation={0} sx={{ p: 2, textAlign: "center" }}>
        <Typography variant="h4" color="text.secondary">
          Volunteer Opportunities
        </Typography>
        <Typography variant="h1" color="primary">
          {countsData?.data.voCount ?? 0}
        </Typography>
      </Paper>
    </Box>
  );
};
