import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import { useState, useRef } from "react";
import { fetcher } from "../../utils/fetchData";

interface CsvUploadDialogProps {
  open: boolean;
  onClose: () => void;
}

export const CsvUploadDialog = ({ open, onClose }: CsvUploadDialogProps) => {
  const [excludeHeader, setExcludeHeader] = useState(true);
  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile && selectedFile.type !== "text/csv") {
      setError("Please upload a CSV file");
      return;
    }
    setFile(selectedFile || null);
    setError(null);
  };

  const handleSubmit = async () => {
    if (!file) return;

    try {
      const text = await file.text();
      const lines = text
        .split("\n")
        .map((line) => line.trim())
        .filter(Boolean);

      if (excludeHeader) {
        lines.shift(); // Remove header row
      }

      const orgs = lines.map((line) => {
        const [org_name, homepage_url] = line.split(",").map((s) => s.trim());
        return { org_name, homepage_url };
      });

      await fetcher<
        { body: { orgs: Array<{ org_name: string; homepage_url: string }> } },
        { message: string }
      >("POST", "/scrape/bulk", { orgs }, process.env.REACT_APP_SCRAPE_API_URL);

      onClose();
    } catch (err) {
      setError("Failed to process CSV file");
      console.error(err);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Upload Organizations CSV</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Upload a CSV file with two columns: Organization Name and Homepage
            URL
          </Typography>
          <input
            type="file"
            accept=".csv"
            onChange={handleFileChange}
            ref={fileInputRef}
            style={{ marginBottom: "1rem", display: "block" }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={excludeHeader}
                onChange={(e) => setExcludeHeader(e.target.checked)}
              />
            }
            label="Exclude first row (header)"
          />
          {error && (
            <Typography color="error" variant="body2" sx={{ mt: 1 }}>
              {error}
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained" disabled={!file}>
          Upload and Start Scraping
        </Button>
      </DialogActions>
    </Dialog>
  );
};
