import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

interface ScrapeAllDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const ScrapeAllDialog: React.FC<ScrapeAllDialogProps> = React.memo(
  ({ open, onClose, onConfirm }) => {

    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Rescrape All Organizations</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will initiate a rescrape of all organizations. This process may
            take a while.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={onConfirm} variant="contained" color="primary">
            Start Scrape
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

ScrapeAllDialog.displayName = "ScrapeAllDialog";
