import {
  Box,
  Typography,
  CircularProgress,
  Collapse,
  IconButton,
} from "@mui/material";
import { useRef, useState, useEffect } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { green, red } from "@mui/material/colors";
import { useOrgMessages } from "./WebSocketProvider";
import { mutate } from "swr";
import { WSLogMessage } from "../../utils/types";

export type ScrapeStatus = "idle" | "running" | "completed" | "error";

export const StatusIndicator = ({
  orgName,
  lastLog,
}: {
  orgName: string;
  lastLog: { success: boolean } | null;
}) => {
  const messages = useOrgMessages(orgName);
  const [status, setStatus] = useState<ScrapeStatus>("idle");

  useEffect(() => {
    if (messages.length === 0) {
      if (lastLog) {
        setStatus(lastLog.success ? "completed" : "error");
      } else {
        setStatus("idle");
      }
      return;
    }

    const lastMessage = messages[messages.length - 1];

    if (lastMessage.type === "complete") {
      // Find the last org_complete message for this org
      const lastOrgComplete = [...messages]
        .reverse()
        .find((msg) => msg.type === "org_complete");
      if (lastOrgComplete) {
        setStatus(lastOrgComplete.success ? "completed" : "error");
      }
    } else if (lastMessage.type === "org_complete") {
      setStatus(lastMessage.success ? "completed" : "error");
    } else if (lastMessage.type === "logs") {
      setStatus("running");
    }
  }, [messages]);

  return (
    <Box
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      {status === "running" ? (
        <CircularProgress size={12} />
      ) : (
        <Box
          sx={{
            width: 8,
            height: 8,
            borderRadius: "50%",
            bgcolor:
              status === "completed"
                ? "success.main"
                : status === "error"
                  ? "error.main"
                  : "grey.400",
          }}
        />
      )}
    </Box>
  );
};

interface ScrapeProgressProps {
  jobId: string;
  onComplete?: () => void;
  orgName: string;
  previousLogs?: Array<{
    success: boolean;
    message: string;
    details: string[];
    timestamp: string;
    vo_changes?: {
      removed: Array<{
        id: string;
        vo_title: string;
        vo_description: string;
      }>;
      added: Array<{
        vo_title: string;
        vo_description: string;
      }>;
    };
  }>;
}

export const ScrapeProgress = ({
  jobId,
  onComplete,
  orgName,
  previousLogs = [],
}: ScrapeProgressProps) => {
  const currentMessages = useOrgMessages(orgName);
  const [isComplete, setIsComplete] = useState(false);
  const [showPreviousLogs, setShowPreviousLogs] = useState(false);
  const latestLogRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Check for completion message
    const completeMessage = currentMessages.find(
      (msg) => msg.type === "org_complete"
    );
    if (completeMessage && !isComplete) {
      setIsComplete(true);
      if (onComplete) onComplete();
    }

    // Auto-scroll to bottom of latest logs
    if (latestLogRef.current) {
      latestLogRef.current.scrollTop = latestLogRef.current.scrollHeight;
    }

    // Check for new_data and update cache
    const orgCompleteMessage = currentMessages.find(
      (msg) => msg.type === "org_complete" && msg.new_data
    );
    if (orgCompleteMessage?.new_data) {
      mutate(
        (key) => typeof key === "string" && key.startsWith("/admin"),
        undefined,
        { revalidate: true }
      );
    }
  }, [currentMessages, isComplete, onComplete]);

  const renderStoredLogs = (logs: typeof previousLogs) => (
    <Box
      sx={{
        bgcolor: "black",
        color: "#00ff00",
        p: 2,
        borderRadius: 1,
        fontFamily: "monospace",
        height: "200px",
        overflowY: "auto",
        whiteSpace: "pre-wrap",
        wordBreak: "break-word",
        "& .timestamp": {
          color: "#888",
          mr: 1,
        },
        "& .success": {
          color: green[500],
        },
        "& .error": {
          color: red[500],
        },
        "& .removed-vo": {
          color: "#ff9800",
        },
        "& .log-divider": {
          borderColor: "rgba(255, 255, 255, 0.1)",
          my: 2,
        },
      }}
    >
      {logs.length === 0 ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          No previous logs available...
        </Box>
      ) : (
        logs.map((log, idx) => (
          <Box key={idx}>
            <Box sx={{ mt: 1, mb: 1 }}>
              <span className="timestamp">
                [{new Date(log.timestamp).toLocaleTimeString()}]
              </span>
              <Box sx={{ ml: 2 }}>
                <span className={log.success ? "success" : "error"}>
                  {log.success ? "✓ Success" : "✗ Failed"}
                </span>
                {log.details?.map((detail, detailIdx) => (
                  <Box key={detailIdx} sx={{ ml: 2 }}>
                    {detail}
                  </Box>
                ))}
                {log.vo_changes && (
                  <Box>
                    {log.vo_changes.removed.length > 0 && (
                      <Box>
                        <div className="removed-vo">Removed VOs:</div>
                        {log.vo_changes.removed.map((vo, voIdx) => (
                          <Box key={voIdx} sx={{ ml: 2, color: red[300] }}>
                            - {vo.vo_title}
                          </Box>
                        ))}
                      </Box>
                    )}
                    {log.vo_changes.added.length > 0 && (
                      <Box
                        sx={{ mt: log.vo_changes.removed.length > 0 ? 1 : 0 }}
                      >
                        <div className="added-vo">Added VOs:</div>
                        {log.vo_changes.added.map((vo, voIdx) => (
                          <Box key={voIdx} sx={{ ml: 2, color: green[300] }}>
                            + {vo.vo_title}
                          </Box>
                        ))}
                      </Box>
                    )}
                    {log.vo_changes.removed.length === 0 &&
                      log.vo_changes.added.length === 0 && (
                        <Box sx={{ color: "#888" }}>
                          No changes to volunteer opportunities
                        </Box>
                      )}
                  </Box>
                )}
              </Box>
            </Box>
            {idx < logs.length - 1 && <hr className="log-divider" />}
          </Box>
        ))
      )}
    </Box>
  );

  const renderLogs = (
    logs: WSLogMessage[],
    ref?: React.RefObject<HTMLDivElement>
  ) => (
    <Box
      ref={ref}
      sx={{
        bgcolor: "black",
        color: "#00ff00",
        p: 2,
        borderRadius: 1,
        fontFamily: "monospace",
        height: "200px",
        overflowY: "auto",
        whiteSpace: "pre-wrap",
        wordBreak: "break-word",
        "& .timestamp": {
          color: "#888",
          mr: 1,
        },
        "& .success": {
          color: green[500],
        },
        "& .error": {
          color: red[500],
        },
        "& .removed-vo": {
          color: "#ff9800",
        },
      }}
    >
      {logs.length === 0 ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          No logs available...
        </Box>
      ) : (
        <>
          {logs
            .sort(
              (a, b) =>
                new Date(a.timestamp).getTime() -
                new Date(b.timestamp).getTime()
            )
            .map((msg, idx) => {
              if (msg.type === "logs" && msg.messages) {
                return (
                  <Box key={idx}>
                    <span className="timestamp">
                      [{new Date(msg.timestamp).toLocaleTimeString()}]
                    </span>
                    {msg.messages.map((logMsg, logIdx) => (
                      <Box key={logIdx} sx={{ ml: 2 }}>
                        {logMsg}
                      </Box>
                    ))}
                  </Box>
                );
              } else if (msg.type === "org_complete") {
                return (
                  <Box key={idx} sx={{ mt: 1, mb: 1 }}>
                    <span className="timestamp">
                      [{new Date(msg.timestamp).toLocaleTimeString()}]
                    </span>
                    <Box sx={{ ml: 2 }}>
                      <span className={msg.success ? "success" : "error"}>
                        {msg.success ? "✓ Success" : "✗ Failed"}
                      </span>
                      {msg.vo_changes && (
                        <Box>
                          {msg.vo_changes.removed.length > 0 && (
                            <Box>
                              <div className="removed-vo">Removed VOs:</div>
                              {msg.vo_changes.removed.map((vo, voIdx) => (
                                <Box
                                  key={voIdx}
                                  sx={{ ml: 2, color: red[300] }}
                                >
                                  - {vo.vo_title}
                                </Box>
                              ))}
                            </Box>
                          )}
                          {msg.vo_changes.added.length > 0 && (
                            <Box
                              sx={{
                                mt: msg.vo_changes.removed.length > 0 ? 1 : 0,
                              }}
                            >
                              <div className="added-vo">Added VOs:</div>
                              {msg.vo_changes.added.map((vo, voIdx) => (
                                <Box
                                  key={voIdx}
                                  sx={{ ml: 2, color: green[300] }}
                                >
                                  + {vo.vo_title}
                                </Box>
                              ))}
                            </Box>
                          )}
                          {msg.vo_changes.removed.length === 0 &&
                            msg.vo_changes.added.length === 0 && (
                              <Box sx={{ color: "#888" }}>
                                No changes to volunteer opportunities
                              </Box>
                            )}
                        </Box>
                      )}
                    </Box>
                  </Box>
                );
              }
              return null;
            })}
          {!isComplete && logs === currentMessages && (
            <Box sx={{ display: "flex", alignItems: "center", gap: 1, mt: 1 }}>
              <CircularProgress size={12} sx={{ color: "#00ff00" }} />
              <span>Processing...</span>
            </Box>
          )}
        </>
      )}
    </Box>
  );

  return (
    <Box>
      {previousLogs.length > 0 && (
        <Box sx={{ mb: currentMessages.length > 0 ? 2 : 0 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              cursor: "pointer",
              color: "text.secondary",
              "&:hover": {
                color: "text.primary",
              },
            }}
            onClick={() => setShowPreviousLogs(!showPreviousLogs)}
          >
            <IconButton size="small">
              {showPreviousLogs ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
            <Typography variant="body2">
              Previous Logs ({previousLogs.length} runs)
            </Typography>
          </Box>
          <Collapse in={showPreviousLogs}>
            <Box sx={{ mt: 1 }}>{renderStoredLogs(previousLogs)}</Box>
          </Collapse>
        </Box>
      )}
      {currentMessages.length > 0 && (
        <Box>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
            Current Logs
          </Typography>
          {renderLogs(currentMessages, latestLogRef)}
        </Box>
      )}
      {currentMessages.length === 0 && previousLogs.length === 0 && (
        <Box
          sx={{
            bgcolor: "black",
            color: "#00ff00",
            p: 2,
            borderRadius: 1,
            fontFamily: "monospace",
            height: "100px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          No logs available yet...
        </Box>
      )}
    </Box>
  );
};
