import React, { useState } from "react";
import { Button, Dialog, DialogTitle } from "@mui/material";
import { ScrapeProgress } from "./ScrapeProgress";
import { fetcher } from "../../utils/fetchData";
import RefreshIcon from "@mui/icons-material/Refresh";

interface ScrapeButtonProps {
  orgId: string;
  size?: "small" | "medium" | "large";
}

export const ScrapeButton: React.FC<ScrapeButtonProps> = ({
  orgId,
  size = "small",
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [jobId, setJobId] = useState<string | null>(null);

  const handleScrape = async () => {
    try {
      const response = await fetcher<never, { job_id: string }>(
        "POST",
        `/scrape/org/${orgId}`,
        undefined,
        process.env.REACT_APP_SCRAPE_API_URL
      );
      setJobId(response.job_id);
      setIsDialogOpen(true);
    } catch (error) {
      console.error("Failed to start scrape:", error);
    }
  };

  return (
    <>
      <Button
        size={size}
        startIcon={<RefreshIcon />}
        onClick={handleScrape}
        color="primary"
      >
        Rescrape
      </Button>

      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            bgcolor: "#1a1a1a",
            color: "white",
          },
        }}
      >
        <DialogTitle sx={{ color: "white" }}>Scraping Progress</DialogTitle>
        {jobId && <ScrapeProgress jobId={jobId} orgName={orgId} />}
      </Dialog>
    </>
  );
};
