import { Box, Button, Paper, Typography } from "@mui/material";
import { useState, useEffect, useCallback, useMemo } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  GeoJSON,
  useMap,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { Feature, FeatureCollection, Geometry, Position } from "geojson";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MarkerClusterGroup from "react-leaflet-cluster";
import { getCounts } from "../../utils/utils";
import useSWR from "swr";
import { fetcher } from "../../utils/fetchData";

// Fix for default marker icons in react-leaflet
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import { StatisticsSidebar } from "../../components/admin/StatisticsSidebar";

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
});

const GreenIcon = L.icon({
  ...DefaultIcon,
  iconUrl:
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png",
  iconSize: [25, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

interface Location {
  id: number;
  latitude: number;
  longitude: number;
  vo: {
    id: number;
    title: string;
    org: string;
  };
}

interface RegionData {
  type: "Feature";
  properties: {
    name: string;
    count: number;
  };
  geometry: Geometry;
}

const MapController = ({
  selectedRegion,
  onReset,
}: {
  selectedRegion: RegionData | null;
  onReset: () => void;
}) => {
  const map = useMap();

  useEffect(() => {
    if (selectedRegion) {
      const bounds = L.geoJSON(selectedRegion, {
        style: { weight: 0, opacity: 0 },
      }).getBounds();
      map.fitBounds(bounds, {
        padding: [50, 50],
        animate: true,
        duration: 0.5,
      });
    } else {
      map.setView([0, 0], 2, {
        animate: true,
        duration: 0.5,
      });
    }
  }, [selectedRegion, map]);

  return null;
};

const BackToWorldControl = ({
  selectedRegion,
  onReset,
}: {
  selectedRegion: RegionData | null;
  onReset: () => void;
}) => {
  const map = useMap();

  // Create a custom control
  useEffect(() => {
    if (!selectedRegion) return;

    const controlDiv = L.DomUtil.create(
      "div",
      "leaflet-control-back leaflet-bar leaflet-control"
    );
    const button = L.DomUtil.create(
      "a",
      "leaflet-control-back-button",
      controlDiv
    );

    // Style the button to match other Leaflet controls
    button.style.width = "30px";
    button.style.height = "30px";
    button.style.display = "flex";
    button.style.alignItems = "center";
    button.style.justifyContent = "center";
    button.style.color = "#666";
    button.style.cursor = "pointer";
    button.title = "Back to World View";

    // Add the icon
    const icon = L.DomUtil.create("span", "material-icons", button);
    icon.innerHTML = "&#xe5c4;"; // Material arrow_back icon
    icon.style.fontSize = "18px";

    L.DomEvent.on(button, "click", (e) => {
      L.DomEvent.stopPropagation(e);
      onReset();
    });

    const control = L.Control.extend({
      options: {
        position: "topleft",
      },
      onAdd: () => controlDiv,
    });

    const customControl = new control();
    customControl.addTo(map);

    return () => {
      map.removeControl(customControl);
    };
  }, [selectedRegion, map, onReset]);

  return null;
};

const mapStyles = `
  .geojson-interactive {
    outline: none !important;
  }
  .leaflet-interactive {
    outline: none !important;
  }
  .leaflet-container {
    outline: none !important;
  }
  .leaflet-control-back-button {
    background-color: #fff;
    border-bottom: 1px solid #ccc;
  }
  .leaflet-control-back-button:hover {
    background-color: #f4f4f4;
  }
  @font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialicons/v140/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
  }
  .material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
  }
  .custom-cluster-icon {
    background-color: rgba(0, 128, 64, 0.8);
    border: 2px solid #fff;
    border-radius: 50%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
  }
`;

const CACHE_KEY = "admin-locations-cache";
const CACHE_EXPIRY = 15 * 60 * 1000; // 15 minutes

const Dashboard = () => {
  const [locations, setLocations] = useState<Location[]>([]);
  const [geoJsonData, setGeoJsonData] = useState<FeatureCollection>();
  const [selectedRegion, setSelectedRegion] = useState<RegionData | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchLocations = useCallback(async () => {
    try {
      setIsLoading(true);

      // Try to get cached data
      const cached = localStorage.getItem(CACHE_KEY);
      if (cached) {
        try {
          const { data, timestamp, geoJson } = JSON.parse(cached);
          // Check if cache is still valid
          if (Date.now() - timestamp < CACHE_EXPIRY) {
            setLocations(data);
            setGeoJsonData(geoJson as FeatureCollection);
            setIsLoading(false);
            return;
          } else {
            // Cache is expired, remove it
            localStorage.removeItem(CACHE_KEY);
          }
        } catch (parseError) {
          // Invalid cache data, remove it
          localStorage.removeItem(CACHE_KEY);
        }
      }

      // Fetch fresh data
      const [locationsResponse, geoJsonResponse] = await Promise.all([
        fetcher<never, { success: boolean; data: Location[] }>("GET", "/admin"),
        fetch("/data/world.geojson").then((res) => res.json()),
      ]);

      if (!locationsResponse.success) {
        throw new Error("Failed to fetch locations");
      }

      // Process GeoJSON data with VO counts
      const regionCounts = locationsResponse.data.reduce(
        (acc, loc) => {
          const region = geoJsonResponse.features.find((feature: Feature) =>
            isPointInPolygon([loc.longitude, loc.latitude], feature.geometry)
          );
          if (region) {
            acc[region.properties.name] =
              (acc[region.properties.name] || 0) + 1;
          }
          return acc;
        },
        {} as Record<string, number>
      );

      const processedGeoJson = {
        type: "FeatureCollection",
        features: geoJsonResponse.features.map((feature: Feature) => ({
          ...feature,
          properties: {
            ...feature.properties,
            count: regionCounts[feature.properties!.name] || 0,
          },
        })),
      };

      // Update state
      setLocations(locationsResponse.data);
      setGeoJsonData(processedGeoJson as FeatureCollection);

      // Update cache storage with better error handling
      try {
        const cacheData = {
          data: locationsResponse.data,
          geoJson: processedGeoJson,
          timestamp: Date.now(),
        };

        // Check if we have enough storage space
        const serializedData = JSON.stringify(cacheData);
        localStorage.setItem(CACHE_KEY, serializedData);
      } catch (cacheError) {
        // If storage is full, clear only old cache entries
        const keys = Object.keys(localStorage);
        for (const key of keys) {
          if (key.endsWith("-cache")) {
            localStorage.removeItem(key);
          }
        }
        // Try storing again
        try {
          localStorage.setItem(
            CACHE_KEY,
            JSON.stringify({
              data: locationsResponse.data,
              geoJson: processedGeoJson,
              timestamp: Date.now(),
            })
          );
        } catch (retryError) {
          console.warn("Failed to store cache after cleanup:", retryError);
        }
      }
    } catch (err) {
      console.error("Error fetching data:", err);
      setError(err instanceof Error ? err.message : "An error occurred");

      // If there's cached data, use it as fallback
      const cached = localStorage.getItem(CACHE_KEY);
      if (cached) {
        const { data, geoJson } = JSON.parse(cached);
        setLocations(data);
        setGeoJsonData(geoJson);
      }
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchLocations();
  }, [fetchLocations]);

  const style = useCallback((feature?: Feature) => {
    if (!feature) return {};
    const count = feature.properties?.count || 0;
    return {
      fillColor: getColor(count),
      weight: 1,
      opacity: 1,
      color: "#9f9f9f",
      dashArray: "2",
      fillOpacity: 0.7,
    };
  }, []);

  const getColor = (count: number) => {
    if (count === 0) return "transparent";

    // Convert count to a value between 0 and 1
    const normalized = Math.min(count / 500, 1);

    // Start and end colors in RGB
    const start = { r: 0xf0, g: 0xff, b: 0xf0 }; // #f0fff0 - honeydew, very light green
    const end = { r: 0x00, g: 0x80, b: 0x40 }; // #008040 - brighter, more saturated green

    // Interpolate between colors
    const r = Math.round(start.r + (end.r - start.r) * normalized);
    const g = Math.round(start.g + (end.g - start.g) * normalized);
    const b = Math.round(start.b + (end.b - start.b) * normalized);

    return `#${r.toString(16).padStart(2, "0")}${g.toString(16).padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;
  };

  const onRegionClick = (feature: Feature) => {
    setSelectedRegion(feature as RegionData);
  };

  const resetView = () => {
    setSelectedRegion(null);
  };

  const filteredLocations = useMemo(() => {
    if (!selectedRegion) return [];
    return locations.filter((loc) =>
      isPointInPolygon([loc.longitude, loc.latitude], selectedRegion.geometry)
    );
  }, [selectedRegion, locations]);

  const createClusterCustomIcon = (cluster: any) => {
    const count = cluster.getChildCount();
    const size = count < 10 ? 30 : count < 100 ? 40 : 50;

    return L.divIcon({
      html: `<div style="width: ${size}px; height: ${size}px;" class="custom-cluster-icon">${count}</div>`,
      className: "",
      iconSize: L.point(size, size, true),
    });
  };

  if (isLoading || !geoJsonData) {
    return (
      <Box
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography color="error">Error: {error}</Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        height: "calc(100vh - 96px)",
        display: "flex",
        flexDirection: "row",
        overflow: "hidden",
      }}
    >
      <style>{mapStyles}</style>

      <Paper
        elevation={0}
        sx={{
          position: "relative",
          width: "100%",
          height: "100%",
          overflow: "hidden",
          borderRadius: 0,
          flex: 1,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            height: "100%",
            width: "100%",
          }}
        >
          <MapContainer
            center={[0, 0]}
            zoom={2}
            style={{
              height: "100%",
              width: "100%",
            }}
            scrollWheelZoom={true}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            <MapController
              selectedRegion={selectedRegion}
              onReset={resetView}
            />

            <BackToWorldControl
              selectedRegion={selectedRegion}
              onReset={resetView}
            />

            {!selectedRegion && (
              <GeoJSON
                data={geoJsonData}
                style={style}
                onEachFeature={(feature, layer) => {
                  layer.on({
                    click: () => onRegionClick(feature),
                  });
                  layer.bindTooltip(
                    `${feature.properties.name}: ${feature.properties.count} VOs`,
                    { sticky: true }
                  );
                }}
              />
            )}

            {selectedRegion && (
              <MarkerClusterGroup
                chunkedLoading
                maxClusterRadius={0.1}
                iconCreateFunction={createClusterCustomIcon}
                spiderfyOnMaxZoom={true}
                showCoverageOnHover={false}
              >
                {filteredLocations.map((location) => (
                  <Marker
                    key={location.id}
                    position={[location.latitude, location.longitude]}
                    icon={GreenIcon}
                  >
                    <Popup>
                      <Box sx={{ p: 0 }}>
                        <Typography variant="caption">
                          {location.vo.title}
                        </Typography>
                        <Typography
                          variant="caption"
                          sx={{
                            color: "text.secondary",
                            display: "block",
                            mt: 0.5,
                          }}
                        >
                          {location.vo.org}
                        </Typography>
                      </Box>
                    </Popup>
                  </Marker>
                ))}
              </MarkerClusterGroup>
            )}
          </MapContainer>
        </Box>
      </Paper>

      <StatisticsSidebar />
    </Box>
  );
};

// Helper function to check if a point is within a polygon using ray casting algorithm
function isPointInPolygon(point: number[], geometry: Geometry): boolean {
  // Extract coordinates from geometry
  let coords: Position[][][] = [];
  if (geometry.type === "Polygon") {
    coords = [geometry.coordinates];
  } else if (geometry.type === "MultiPolygon") {
    coords = geometry.coordinates;
  }

  // For each polygon (could be multiple parts of a country)
  for (const polygon of coords) {
    // First array is outer ring, rest are holes
    const outerRing = polygon[0];
    let insideOuter = false;

    // Check if point is inside outer ring
    for (let i = 0, j = outerRing.length - 1; i < outerRing.length; j = i++) {
      const [xi, yi] = outerRing[i];
      const [xj, yj] = outerRing[j];

      const intersect =
        yi > point[1] !== yj > point[1] &&
        point[0] < ((xj - xi) * (point[1] - yi)) / (yj - yi) + xi;

      if (intersect) insideOuter = !insideOuter;
    }

    // If point is inside outer ring, check it's not in any holes
    if (insideOuter) {
      let insideHole = false;

      // Check each hole (rings after first)
      for (let h = 1; h < polygon.length; h++) {
        const holeRing = polygon[h];
        let inCurrentHole = false;

        for (let i = 0, j = holeRing.length - 1; i < holeRing.length; j = i++) {
          const [xi, yi] = holeRing[i];
          const [xj, yj] = holeRing[j];

          const intersect =
            yi > point[1] !== yj > point[1] &&
            point[0] < ((xj - xi) * (point[1] - yi)) / (yj - yi) + xi;

          if (intersect) inCurrentHole = !inCurrentHole;
        }

        if (inCurrentHole) {
          insideHole = true;
          break;
        }
      }

      // Point is truly inside if it's in outer ring but not in any hole
      if (!insideHole) return true;
    }
  }

  return false;
}

export default Dashboard;
