import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { useState, useEffect } from "react";

const STATES = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

export interface Filters {
  state: string | null;
  failedScrape: boolean;
  startDate: string | null;
  endDate: string | null;
}

interface FilterBarProps {
  filters: Filters;
  onFiltersChange: (filters: Filters) => void;
}

export const FilterBar: React.FC<FilterBarProps> = ({
  filters,
  onFiltersChange,
}) => {
  const handleStateChange = (event: SelectChangeEvent<string>) => {
    onFiltersChange({
      ...filters,
      state: event.target.value === "all" ? null : event.target.value,
    });
  };

  const handleFailedScrapeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    onFiltersChange({
      ...filters,
      failedScrape: event.target.checked,
    });
  };

  const handleDateChange =
    (field: "startDate" | "endDate") =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onFiltersChange({
        ...filters,
        [field]: event.target.value || null,
      });
    };

  return (
    <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
      <FormControlLabel
        control={
          <Switch
            checked={filters.failedScrape}
            onChange={handleFailedScrapeChange}
          />
        }
        label="Failed Scrapes"
      />
      <FormControl sx={{ minWidth: 120 }}>
        <InputLabel>State</InputLabel>
        <Select
          value={filters.state ?? "all"}
          onChange={handleStateChange}
          label="State"
        >
          <MenuItem value="all">All States</MenuItem>
          {STATES.map((state) => (
            <MenuItem key={state} value={state}>
              {state}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        type="date"
        label="Start Date"
        value={filters.startDate || ""}
        onChange={handleDateChange("startDate")}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        type="date"
        label="End Date"
        value={filters.endDate || ""}
        onChange={handleDateChange("endDate")}
        InputLabelProps={{ shrink: true }}
      />
    </Box>
  );
};
