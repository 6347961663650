import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  Paper,
  IconButton,
  Grid,
  Typography,
  Box,
  FormLabel,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import { useState, useEffect } from "react";
import { fetcher } from "../../utils/fetchData";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { useSWRConfig } from "swr";
import { DatabaseOrg, DatabaseVO } from "../../utils/types";

// Keep the interfaces, CAUSES, SKILLS, and STATES from the previous AddEditForm

export interface Location {
  street_address?: string;
  city?: string;
  zip_code?: string;
  state: string;
  county?: string;
}

export interface VOFormData {
  vo_title: string;
  vo_description: string;
  vo_min_age: number;
  vo_skills: string[];
  vo_url: string;
  locations: Location[];
}

export interface OrgFormData {
  org_name: string;
  logo_url: string;
  domain_url: string;
  org_causes: string[];
  org_desc: string;
}

const CAUSES = [
  "Agriculture & Rural Areas",
  "Animals",
  "Arts & Culture",
  "Children & Youth",
  "Disability",
  "Disaster Relief",
  "Education",
  "Environment & Nature",
  "Finance & Business",
  "Immigrants & Refugees",
  "Justice & Legal Assistance",
  "LGBTQ+",
  "Health & Medical Research",
  "Poverty",
  "Race & Ethnicity",
  "Religion & Spirituality",
  "Science & Technology",
  "Seniors",
  "Sports & Recreation",
  "Veterans & Military Families",
  "Women",
];

const SKILLS = [
  "Administrative",
  "Arts",
  "Cooking",
  "Delivery/Driving",
  "Financial",
  "Healthcare/Medical",
  "Interpersonal",
  "Legal",
  "Communications/Marketing",
  "Nature/Animal",
  "Skilled Craft or Trade",
  "Sports & Recreation",
  "Teach, Mentor, Speak",
  "Technology",
  "Writing/Research",
  "Other",
];

const STATES = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

interface AddEditModalProps {
  open: boolean;
  onClose: () => void;
  type: "org" | "vo";
  id?: string;
  orgId?: string; // For VOs
  initialData?: OrgFormData | VOFormData;
}

const AddEditModal = ({
  open,
  onClose,
  type,
  id,
  orgId,
  initialData,
}: AddEditModalProps) => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { mutate } = useSWRConfig();

  // Define default empty states
  const defaultOrgData: OrgFormData = {
    org_name: "",
    logo_url: "",
    domain_url: "",
    org_causes: [],
    org_desc: "",
  };

  const defaultVoData: VOFormData = {
    vo_title: "",
    vo_description: "",
    vo_min_age: 0,
    vo_skills: [],
    vo_url: "",
    locations: [{ state: "" }],
  };

  const [orgData, setOrgData] = useState<OrgFormData>(defaultOrgData);
  const [voData, setVoData] = useState<VOFormData>(defaultVoData);

  // Set initial data when editing
  useEffect(() => {
    if (initialData) {
      if (type === "vo") {
        setVoData(initialData as VOFormData);
      } else {
        setOrgData(initialData as OrgFormData);
      }
    }
  }, [initialData, type]);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const method = id ? "PATCH" : "POST";
      const endpoint =
        type === "vo"
          ? `/admin?type=vo${id ? `&id=${id}` : orgId ? `&org_id=${orgId}` : ""}`
          : `/admin?type=org${id ? `&id=${id}` : ""}`;

      const response = await fetcher<
        { body: VOFormData | OrgFormData },
        { success: boolean; data: DatabaseVO | DatabaseOrg }
      >(method, endpoint, type === "vo" ? voData : orgData);

      if (!response.success) {
        throw new Error("Failed to save data");
      }

      await mutate(
        (key: any) => key[0] === "/admin" && key[1].type === "orgs",
        (current: any) => {
          if (!current) return current;
          const updatedData = {
            ...current,
            data: {
              ...current.data,
              data: current.data.data.map((org: any) => {
                if (org.id === orgId) {
                  if (type === "vo") {
                    return {
                      ...org,
                      vos: org.vos.map((vo: DatabaseVO) => {
                        if (vo.id === id) {
                          return { ...vo, ...response.data };
                        }
                        return vo;
                      }),
                    };
                  } else {
                    console.log("org", org);
                    console.log("response.data", response.data);
                    return { ...org, ...response.data };
                  }
                }
                return org;
              }),
            },
          };
          return updatedData;
        },
        {
          revalidate: false,
          populateCache: true,
        }
      );

      onClose();
    } catch (err) {
      console.error("Error saving data:", err);
      setError(err instanceof Error ? err.message : "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  const handleLocationChange = (
    index: number,
    field: keyof Location,
    value: string
  ) => {
    const newLocations = [...voData.locations];
    newLocations[index] = { ...newLocations[index], [field]: value };
    setVoData({ ...voData, locations: newLocations });
  };

  const addLocation = () => {
    setVoData({
      ...voData,
      locations: [...voData.locations, { state: "" }],
    });
  };

  const removeLocation = (index: number) => {
    const newLocations = voData.locations.filter((_, i) => i !== index);
    setVoData({ ...voData, locations: newLocations });
  };

  const handleClose = () => {
    setError(null);
    setLoading(false);
    setOrgData(defaultOrgData);
    setVoData(defaultVoData);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          "& .MuiDialogTitle-root": {
            pt: 2,
            pb: 1.5,
            px: 2,
          },
          "& .MuiDialogContent-root": {
            py: 1.5,
            px: 2,
          },
          "& .MuiDialogActions-root": {
            py: 1,
            px: 2,
          },
        },
      }}
    >
      <DialogTitle>
        {id ? "Edit" : "Add"}{" "}
        {type === "vo" ? "Volunteer Opportunity" : "Organization"}
      </DialogTitle>
      <DialogContent>
        {type === "vo" ? (
          // VO Form
          <>
            <Grid container spacing={1.5}>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  fullWidth
                  label="Title"
                  value={voData.vo_title}
                  onChange={(e) =>
                    setVoData({ ...voData, vo_title: e.target.value })
                  }
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  fullWidth
                  multiline
                  rows={3}
                  label="Description"
                  value={voData.vo_description}
                  onChange={(e) =>
                    setVoData({ ...voData, vo_description: e.target.value })
                  }
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  fullWidth
                  label="URL"
                  value={voData.vo_url}
                  onChange={(e) =>
                    setVoData({ ...voData, vo_url: e.target.value })
                  }
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  size="small"
                  fullWidth
                  type="number"
                  label="Minimum Age"
                  value={voData.vo_min_age}
                  onChange={(e) =>
                    setVoData({
                      ...voData,
                      vo_min_age: parseInt(e.target.value),
                    })
                  }
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl component="fieldset" sx={{ width: "100%" }}>
                  <FormLabel
                    component="legend"
                    sx={{ fontSize: "0.875rem", mb: 1 }}
                  >
                    Skills (select up to 3)
                  </FormLabel>
                  <FormGroup row>
                    {SKILLS.map((skill) => (
                      <FormControlLabel
                        key={skill}
                        control={
                          <Checkbox
                            checked={voData.vo_skills.includes(skill)}
                            onChange={(e) => {
                              if (
                                e.target.checked &&
                                voData.vo_skills.length < 3
                              ) {
                                setVoData({
                                  ...voData,
                                  vo_skills: [...voData.vo_skills, skill],
                                });
                              } else if (!e.target.checked) {
                                setVoData({
                                  ...voData,
                                  vo_skills: voData.vo_skills.filter(
                                    (s) => s !== skill
                                  ),
                                });
                              }
                            }}
                            size="small"
                          />
                        }
                        label={<Typography variant="body2">{skill}</Typography>}
                        sx={{ width: "33%", mr: 0 }}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>

            <Typography sx={{ mt: 2, mb: 1 }} fontWeight={"bold"}>
              Locations
            </Typography>

            {voData.locations.map((location, index) => (
              <Paper key={index} variant="outlined" sx={{ p: 1.5, mb: 1.5 }}>
                <Grid container spacing={1.5}>
                  <Grid item xs={12}>
                    <TextField
                      size="small"
                      fullWidth
                      label="Street Address"
                      value={location.street_address || ""}
                      onChange={(e) =>
                        handleLocationChange(
                          index,
                          "street_address",
                          e.target.value
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      size="small"
                      fullWidth
                      label="City"
                      value={location.city || ""}
                      onChange={(e) =>
                        handleLocationChange(index, "city", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      size="small"
                      fullWidth
                      label="ZIP Code"
                      value={location.zip_code || ""}
                      onChange={(e) =>
                        handleLocationChange(index, "zip_code", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth size="small">
                      <InputLabel>State</InputLabel>
                      <Select
                        value={location.state}
                        onChange={(e) =>
                          handleLocationChange(index, "state", e.target.value)
                        }
                        required
                      >
                        {STATES.map((state) => (
                          <MenuItem key={state} value={state}>
                            {state}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      size="small"
                      fullWidth
                      label="County"
                      value={location.county || ""}
                      onChange={(e) =>
                        handleLocationChange(index, "county", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <IconButton
                        size="small"
                        color="error"
                        onClick={() => removeLocation(index)}
                        disabled={voData.locations.length === 1}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            ))}

            <Button
              startIcon={<AddIcon />}
              onClick={addLocation}
              sx={{ mt: 2 }}
            >
              Add Location
            </Button>
          </>
        ) : (
          // Organization Form
          <Grid container spacing={1.5}>
            <Grid item xs={12}>
              <TextField
                size="small"
                fullWidth
                label="Organization Name"
                value={orgData.org_name}
                onChange={(e) =>
                  setOrgData({ ...orgData, org_name: e.target.value })
                }
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                fullWidth
                label="Domain URL"
                value={orgData.domain_url}
                onChange={(e) =>
                  setOrgData({ ...orgData, domain_url: e.target.value })
                }
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                fullWidth
                label="Logo URL"
                value={orgData.logo_url}
                onChange={(e) =>
                  setOrgData({ ...orgData, logo_url: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset" sx={{ width: "100%" }}>
                <FormLabel
                  component="legend"
                  sx={{ fontSize: "0.875rem", mb: 1 }}
                >
                  Causes (select up to 3)
                </FormLabel>
                <FormGroup row>
                  {CAUSES.map((cause) => (
                    <FormControlLabel
                      key={cause}
                      control={
                        <Checkbox
                          checked={orgData.org_causes.includes(cause)}
                          onChange={(e) => {
                            if (
                              e.target.checked &&
                              orgData.org_causes.length < 3
                            ) {
                              setOrgData({
                                ...orgData,
                                org_causes: [...orgData.org_causes, cause],
                              });
                            } else if (!e.target.checked) {
                              setOrgData({
                                ...orgData,
                                org_causes: orgData.org_causes.filter(
                                  (c) => c !== cause
                                ),
                              });
                            }
                          }}
                          size="small"
                        />
                      }
                      label={<Typography variant="body2">{cause}</Typography>}
                      sx={{ width: "33%", mr: 0 }}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                fullWidth
                multiline
                rows={3}
                label="Description"
                value={orgData.org_desc}
                onChange={(e) =>
                  setOrgData({ ...orgData, org_desc: e.target.value })
                }
                required
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={loading}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit} disabled={loading}>
          {loading ? "Saving..." : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddEditModal;
