import { supabase } from "./auth";

export function isValidEmail(email: string) {
  const regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return regex.test(email);
}

const getFallbackLocation = () => {};

export const debounce = <T extends (...args: any[]) => any>(
  func: T,
  wait: number,
  options: { leading?: boolean; trailing?: boolean } = {}
) => {
  let timeout: NodeJS.Timeout | undefined;
  let lastArgs: Parameters<T> | undefined;
  let lastThis: any;
  let result: ReturnType<T>;
  let lastCallTime: number | undefined;

  const leading = options.leading ?? false;
  const trailing = options.trailing ?? true;

  function debounced(this: any, ...args: Parameters<T>) {
    const now = Date.now();

    if (!lastCallTime && !leading) {
      lastCallTime = now;
    }

    const remaining = wait - (now - (lastCallTime ?? 0));

    lastArgs = args;
    lastThis = this;

    const invokeFunction = () => {
      lastCallTime = leading ? 0 : Date.now();
      timeout = undefined;
      result = func.apply(lastThis, lastArgs!);
      lastThis = lastArgs = undefined;
      return result;
    };

    const shouldInvoke = (time: number) => {
      const timeSinceLastCall = time - (lastCallTime ?? 0);
      return !lastCallTime || timeSinceLastCall >= wait;
    };

    if (timeout) {
      clearTimeout(timeout);
    }

    if (shouldInvoke(now)) {
      return invokeFunction();
    }

    if (trailing) {
      timeout = setTimeout(invokeFunction, remaining);
    }

    return result;
  }

  // Add cancel method
  debounced.cancel = () => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = undefined;
      lastCallTime = undefined;
      lastArgs = undefined;
      lastThis = undefined;
    }
  };

  return debounced;
};

export const getCounts = async () => {
  const { data: voCount, error: voError } = await supabase.rpc("count_vos");
  const { count: orgCount, error: orgError } = await supabase
    .from("orgs")
    .select("*", { count: "exact", head: true });

  if (voError || orgError || !voCount || !orgCount) {
    console.error("Error fetching counts:", voError || orgError);
    return { voCount: 0, orgCount: 0 };
  }

  return { voCount, orgCount };
};
