import { Box, Typography } from "@mui/material";
import { useWebSocketStatus } from "./WebSocketProvider";

const title = (text: string) => text.charAt(0).toUpperCase() + text.slice(1);

export const WebSocketStatusIndicator = () => {
  const status = useWebSocketStatus();

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <Box
        sx={{
          width: 8,
          height: 8,
          borderRadius: "50%",
          bgcolor:
            status === "connected"
              ? "success.main"
              : status === "connecting"
                ? "warning.main"
                : "error.main",
        }}
      />
      <Typography variant="caption" color="text.secondary">
        Scraping Server: {title(status)}
      </Typography>
    </Box>
  );
};
