import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Box,
  SelectChangeEvent,
} from "@mui/material";
import SortIcon from "@mui/icons-material/Sort";

export interface SortOptions {
  sortBy: "org_name" | "updated_at";
  sortOrder: "asc" | "desc";
}

interface SortControlsProps {
  sort: SortOptions;
  onSortChange: (sort: SortOptions) => void;
}

export const SortControls: React.FC<SortControlsProps> = ({
  sort,
  onSortChange,
}) => {
  const handleSortByChange = (event: SelectChangeEvent<string>) => {
    onSortChange({
      ...sort,
      sortBy: event.target.value as SortOptions["sortBy"],
    });
  };

  const handleSortOrderToggle = () => {
    onSortChange({
      ...sort,
      sortOrder: sort.sortOrder === "asc" ? "desc" : "asc",
    });
  };

  return (
    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
      <FormControl sx={{ minWidth: 120 }}>
        <InputLabel>Sort By</InputLabel>
        <Select
          value={sort.sortBy}
          onChange={handleSortByChange}
          label="Sort By"
        >
          <MenuItem value="org_name">Name</MenuItem>
          <MenuItem value="updated_at">Last Updated</MenuItem>
        </Select>
      </FormControl>
      <IconButton
        onClick={handleSortOrderToggle}
        sx={{
          transform: sort.sortOrder === "desc" ? "rotate(180deg)" : "none",
          transition: "transform 0.2s",
        }}
      >
        <SortIcon />
      </IconButton>
    </Box>
  );
};
